<script>
	import {
		dbGameSessionRoundValue,
		dbGameSessionRounds,
		dbHost,
		dbUser,
		dbUsers,
		dbHostAction,
		listenFirebaseKey,
		dbBgColor,
	} from "./utilities/database";
	import Game from "./pages/Game.svelte";
	import ChooseCategory from "./pages/ChooseCategory.svelte";
	import YouAreOffline from "./pages/YouAreOffline.svelte";
	import { getParams } from "./utilities/utils";
	import { gameStart } from "./utilities/store";
	import { playSound } from "./utilities/audio";
	import Welcome from "./pages/Welcome.svelte";
	import Timer from "./pages/Timer.svelte";
	import HalfTime from "./pages/HalfTime.svelte";
	import Header from "./components/Header.svelte";
	import IconLarge from "./icons/IconLarge.svelte"
  	import { info } from "./utilities/Notifier";
	import Notification from "./components/Notification.svelte";
	import { defaultColor } from "./utilities/utils";
	import { onDestroy, onMount } from "svelte";

	let page;
	let dbGameSessionRound;
	let roundValue;
	let user;
	let usersOnlineStatus = {};
	let users;
	let usersArray = [];
	let hostAction = {};
	let backgroundColor;
	let splash = true;

	onMount(() => {
		playSound("BGMUSIC");
		setTimeout(() => {
			splash = false;
		}, 2000);
	});

	onDestroy(() => {
		playSound("MUTE");
	});

	dbBgColor.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		backgroundColor = snap.val();
	});

	const snapFun = function (snap) {
		if (!snap.exists()) {
			gameStart.update(() => false);

			if (roundValue !== 1) {
				page = "Welcome"; // Only for now
			} else {
				page = "Welcome";
			}
			return;
		}
		page = snap.val().page;
		splash = false;
		if (!page) {
			page = "Welcome";
			gameStart.update(() => false);
			return;
		}

		if (!(page === "Welcome" || page === "Category")) {
			playSound("BGMUSICSTOP");
		}

		if (page !== "Timer" && page !== "Welcome" && page !== "Category") {
			gameStart.update(() => true);
		} else {
			gameStart.update(() => false);
		}
	};
	dbUser.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		user = snap.val();
	});
	dbUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		users = snap.val();
	});

	listenFirebaseKey(dbHostAction, dbHostActionRef => {
		dbHostActionRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			hostAction = snap.val();
		});
	});

	$: {
		if (users) {
			usersArray = [];
			for (const id in users) {
				let currUser = users[id];
				usersArray.push(currUser);
				if (currUser.isOnline === false) {
					if (id in usersOnlineStatus && usersOnlineStatus[id] == true) {
						if (id === userId) {
							info(
								"You have been disconnected, please check your internet connection!",
								"Disconnected",
								5000
							);
						} else {
							info(`${users[id]["userName"].split(" ")[0]} is disconnected!`, "Disconnected", 5000);
						}
					}
					usersOnlineStatus[id] = false;
				} else if (currUser.isOnline === true) {
					if (id in usersOnlineStatus && usersOnlineStatus[id] === false) {
						if (id === userId) {
							info("You are reconnected!", "Reconnected", 5000);
						} else {
							info(`${users[id]["userName"].split(" ")[0]} is reconnected!`, "Reconnected", 5000);
						}
					}
					usersOnlineStatus[id] = true;
				}
			}
		}
	}

	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
		if (dbGameSessionRound) {
			dbGameSessionRound.off("value", snapFun);
		}

		dbGameSessionRound = dbGameSessionRounds.child(roundValue);
		dbGameSessionRound.on("value", snapFun);
	});

	let userId = getParams("userId");
	let hostId;
	if (getParams("isHost") === "true") {
		dbHost
			.get()
			.then(snap => {
				hostId = snap.val();
				if (!hostId) {
					dbHost.set(userId);
				}
			})
			.catch(() => {
				dbHost.set(userId);
			});
	}

	let hostName;
	dbHost.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		if (hostId) {
			const oldHostName = usersArray.find(user => user.id === hostId)?.userName.split(" ")[0];
			const newHostName = usersArray.find(user => user.id === snap.val())?.userName.split(" ")[0];
			let message = "";
			if (snap.val() === getParams("userId")) {
				message = `${oldHostName || "Old Host"} has left the game and you are the new host!`;
			} else {
				if (newHostName) {
					message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}!`;
				} else {
					message = `${oldHostName || "Old Host"} has left the game and new host has been assigned!`;
				}
			}
			info(message, "HostDisconnected", 5000);
		}
		hostId = snap.val();
	});
	$: {
		if (users && hostId !== undefined) {
			hostName = users[hostId]["userName"].split(" ")[0];
		}
	}
</script>

<main style="--bg-color: {backgroundColor || defaultColor}">
	{#if !splash}
		<Header />
		{#if !user?.isOnline}
			<YouAreOffline />
		{:else if page === "Welcome"}
			<Welcome />
		{:else if page === "Category"}
			<ChooseCategory />
		{:else if page === "Timer"}
			<Timer />
		{:else if page === "Game"}
			<Game />
		{:else if page === "HalfTime"}
			<HalfTime />
		{/if}
		<Notification />
	{:else}
		<div class="splash">
			<IconLarge />
		</div>
	{/if}
</main>

<style>
	:global(*) {
		box-sizing: border-box;
	}

	.splash {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}

	@media screen and (max-width: 1000px) {
		:global(html) {
			font-size: 18px;
		}
	}
	@media screen and (max-width: 900px) {
		:global(html) {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 800px) {
		:global(html) {
			font-size: 14px;
		}
	}
	@media screen and (max-width: 700px) {
		:global(html) {
			font-size: 12px;
		}
	}
	main {
		background: url(/images/background.svg), var(--bg-color);
		width: 100vw;
		height: 100vh;
		background-size: 100vw 100vh;
		padding: 1rem;
	}
</style>
