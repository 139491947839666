<script>
	import { getParams } from "../utilities/utils";
	import {
		dbUsers,
		dbHost,
		dbAllQuestion,
		listenFirebaseKey,
		dbCurrentQuestionNumber,
		dbGameSessionRoundValue,
		dbHalfTimer,
		dbDisableTimer,
		dbHostAction,
		dbBgColor,
	} from "../utilities/database";
	import CustomButton from "../components/CustomButton.svelte";
	import LineBreak from "../icons/LineBreak.svelte";
	import YouAreOffline from "./YouAreOffline.svelte";
	import ProfilePicture from "../components/ProfilePicture.svelte";
	import TriviaIconNew from "../icons/TriviaIconNew.svelte";
	import { Confetti } from "../utilities/confetti";
	import { onDestroy, onMount } from "svelte";
	import { defaultColor } from "../utilities/utils";
	import { gameStart } from "../utilities/store";
	import { playSound } from "../utilities/audio";
	
	let userId = getParams("userId");
	let users;
	let usersArray = [];
	let hostId;
	let hostName;
	let isHost;
	let allQuestions;
	let currentQuestionNumber;
	let roundValue;
	let halfTime = 5;
	let timeVal, timeVal1;
	let noOfOnlinePlayers;
	let svgId;
	let stroke;
	let pageHasRefreshed = true;
	let maxValueOfStroke;
	let strokeDashOffset;
	let stroke1;
	let requestAnimationFrameId;
	let isFloating = false;
	let isTop = false;
	let userScoreCoord;
	let leaderboardCoord;

	let confettiTimeOut;

	onMount(() => {
		maxValueOfStroke = 2 * (svgId.offsetWidth + svgId.offsetHeight);
		strokeDashOffset = svgId.offsetWidth / 2;
		stroke1 = maxValueOfStroke;
		stroke = 0;
	});

	onDestroy(() => {
		clearTimeout(confettiTimeOut);
		Confetti.stopSpawning();
		Confetti.removeCanvas();

		playSound("APPLAUSESTOP");
	});

	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
	});

	listenFirebaseKey(dbCurrentQuestionNumber, dbCurrentQuestionNumberRef => {
		dbCurrentQuestionNumberRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			currentQuestionNumber = snap.val();
			pageHasRefreshed = true;
		});
	});

	listenFirebaseKey(dbHalfTimer, dbHalfTimerRef => {
		dbHalfTimerRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}

			halfTime = snap.val();
			if (halfTime === 0) {
				disableContinueBtn = true;
			} else {
				disableContinueBtn = false;
				timeVal1 = halfTime;
				if (halfTime <= 0) {
					cancelAnimationFrame(requestAnimationFrameId);
					stroke = 0;
					stroke1 = maxValueOfStroke - stroke;
				} else if (halfTime <= 6 && pageHasRefreshed) {
					pageHasRefreshed = false;
					prev = null;
					timeVal = halfTime;
					cancelAnimationFrame(requestAnimationFrameId);
					requestAnimationFrameId = requestAnimationFrame(setStroke);
				}
			}
		});
	});

	dbUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		users = snap.val();
	});
	dbHost.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		hostId = snap.val();
	});
	$: {
		if (users) {
			noOfOnlinePlayers = 0;
			for (const id in users) {
				if (users[id].isOnline === true) {
					noOfOnlinePlayers += 1;
				}
			}
		}
	}
	$: {
		if (hostId && users) {
			hostName = users[hostId]["userName"].split(" ")[0];
		}
	}
	listenFirebaseKey(dbAllQuestion, dbAllQuestionRef => {
		dbAllQuestionRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			allQuestions = snap.val();
		});
	});
	let scoreOfUser = {};
	let scoreLeaders = [];
	let isItTie = false;
	let isTieWithUser = false;
	let singleWinner = false;
	let isUserSingleWinner = false;
	let isZeroScore = false;
	let isAllTie = false;
	$: {
		if (allQuestions && users && currentQuestionNumber) {
			scoreLeaders = [];
			scoreOfUser = {};
			for (let i = 0; i <= currentQuestionNumber; i++) {
				let currQuestionAnswers = allQuestions[i]["usersAnswers"];
				let correctOption = allQuestions[i]["correctOption"];
				for (const userId in currQuestionAnswers) {
					let currUserAnswer = currQuestionAnswers[userId];
					if (currUserAnswer === correctOption) {
						if (scoreOfUser[userId] === undefined) {
							scoreOfUser[userId] = 1;
						} else {
							scoreOfUser[userId] += 1;
						}
					}
				}
			}
			usersArray = [];
			for (const userId in users) {
				if (scoreOfUser[userId] === undefined) {
					scoreOfUser[userId] = 0;
				}

				if (users[userId].isOnline) {
					usersArray.push({
						user: users[userId],
						score: scoreOfUser[userId],
					});
				}
			}

			usersArray.sort((a, b) => {
				return b.score - a.score;
			});

			usersArray = usersArray;

			if (usersArray.length >= 1 && usersArray[0].score === 0) {
				isZeroScore = true;
			}

			if (usersArray.length === 1) {
				scoreLeaders = [{ ...usersArray[0].user, score: usersArray[0].score }];
			} else {
				let i = 1;
				scoreLeaders.push({ ...usersArray[0].user, score: usersArray[0].score });
				if (usersArray[0].user.id === userId) {
					isTieWithUser = true;
				}

				while (i < usersArray.length && usersArray[i].score === usersArray[0].score) {
					if (usersArray[i].user.id === userId) {
						isTieWithUser = true;
					}
					scoreLeaders.push({ ...usersArray[i].user, score: usersArray[i].score });
					i++;
				}

				if (scoreLeaders.length > 1) {
					isItTie = true;
					if (scoreLeaders.length === usersArray.length) {
						isAllTie = true;
					}
				} else {
					singleWinner = true;
					if (scoreLeaders[0].id === userId) {
						isUserSingleWinner = true;
					}
				}
			}
		}
	}

	let prev, curr, elapsed;
	function setStroke(timeStamp) {
		if (!svgId) {
			return;
		}
		if (!prev) {
			prev = timeStamp;
			timeVal = timeVal1;
		} else {
			curr = timeStamp;
			elapsed = curr - prev;
			timeVal = timeVal - elapsed / 1000;
			prev = curr;
		}
		maxValueOfStroke = 2 * (svgId.offsetWidth + svgId.offsetHeight);
		strokeDashOffset = 1 * (svgId.offsetWidth / 2);
		if (timeVal <= 0) {
			cancelAnimationFrame(requestAnimationFrameId);
			prev = null;
			stroke = 0;
			stroke1 = maxValueOfStroke - stroke;
		} else {
			stroke = ((4.5 - timeVal) * maxValueOfStroke) / 4.5;
			stroke1 = maxValueOfStroke - stroke;
			requestAnimationFrameId = requestAnimationFrame(setStroke);
		}
	}

	function processName(user) {
		let name = user.userName;
		let fname = name?.split(" ")[0];
		let lname = name?.split(" ")[1];
		if (fname?.length > 7) {
			fname = fname[0].toUpperCase();
			if (lname) {
				fname += lname[0].toUpperCase();
			}
		}
		if (user.id === hostId) {
			if (userId === hostId) {
				fname = fname + " (You)";
			} else {
				fname = fname + " (Host)";
			}
		} else if (user.id === userId) {
			if (!isHost) {
				fname = fname + " (You)";
			}
		}
		return fname;
	}

	let disableContinueBtn = false;
	function handleContinueButton() {
		playSound("CLICK");
		if (disableContinueBtn) {
			return;
		}
		disableContinueBtn = true;
		if (currentQuestionNumber === 4) {
			listenFirebaseKey(dbHalfTimer, async dbHalfTimerRef => {
				dbHalfTimerRef.set(0).then(() => {
					listenFirebaseKey(dbHostAction, dbHostActionRef => {
						dbHostActionRef.set({
							action: "Continue Game",
							time: Date.now(),
						});
					});
				});
			});
		} else if (currentQuestionNumber === 9) {
			dbGameSessionRoundValue
				.set(roundValue + 1)
				.then(async () => {
					playSound("BGMUSIC");
					await dbBgColor.set(defaultColor);
					listenFirebaseKey(dbHostAction, dbHostActionRef => {
						dbHostActionRef.set({
							action: "Restart Game",
							time: Date.now(),
						});
					});
				})
				.catch(err => {
					console.log("Something went wrong while incrementing round value ", err);
				});
		}
	}

	$: {
		if (hostId !== userId) {
			isHost = false;
		} else {
			isHost = true;
		}
	}

	function confettiAnimation() {
		Confetti.startSpawning();
		confettiTimeOut = setTimeout(function () {
			Confetti.stopSpawning();
			Confetti.removeCanvas();
		}, 5000);
	}

	function scrollLeaderboard() {

		if (!userScoreCoord || !leaderboardCoord) {
			return;
		}

		const offsetBottom = userScoreCoord.getBoundingClientRect().top - leaderboardCoord.getBoundingClientRect().bottom;
		const offsetTop = userScoreCoord.getBoundingClientRect().bottom - leaderboardCoord.getBoundingClientRect().top;

		if (offsetBottom > 0 || offsetTop < 0) {
			isFloating = true;	 
			if (offsetTop < 0) {
				isTop = true;
			} else isTop = false;
		} else {
			isFloating = false;
		}
	}

	$: {
		if (userScoreCoord && leaderboardCoord) {
			scrollLeaderboard();
		}
	}
</script>

{#if usersArray.length}
	<div class="halfTime">
		<TriviaIconNew />

		<div class="parentContainer">
			<div class="resultScreenContainer">
				<div class="contentHeaderContainer">
					<div class="contentHeader" style="background : #6c44a8">
						{#if currentQuestionNumber === 4}
							Half Time
						{:else}
							{(confettiAnimation(), playSound("APPLAUSE"), "")}
							Leaderboard
						{/if}
					</div>
				</div>
				<div class="svgContainer" bind:this={svgId}>
					<svg class="svg" width="100%" height="100%">
						<rect
							x="0"
							y="0"
							width="100%"
							height="100%"
							fill="#6c44a8"
							stroke-dashoffset={-1 * strokeDashOffset}
							stroke-dasharray="{stroke} , {stroke1}"
						/>
					</svg>
				</div>
				<div class="resultScreen">
					<div class="answerScreen">
						<div class="content">
							{#if currentQuestionNumber === 4}
								5 Down, 5 More to Go!
							{:else if currentQuestionNumber === 9}
								{#if isZeroScore}
									OOPS! Better luck Next time
								{:else if isUserSingleWinner}
									Wohhoo! You Won 🔥
								{:else if !isUserSingleWinner && singleWinner}
									That's a Clear Win 🎉
								{:else if isAllTie}
									It's a Tie! 🤝
								{:else if isTieWithUser && !isAllTie}
									You Won & it's a Tie! 🤝
								{:else if !isTieWithUser && isItTie}
									Damn! It's a Tie 🤝 Well Played
								{/if}
							{/if}
							<br />
							<LineBreak />
						</div>
						<div class="leaderboard" on:scroll={scrollLeaderboard} bind:this={leaderboardCoord}>
							{#each usersArray as currUser}
								{#if currUser.user.id === userId} 
									{#if isFloating === true}
										<div
											class="user you floating"
											class:floatingTop={isTop}
											class:winner={currUser.score === scoreLeaders[0].score}
										>
											<div class="userDetails">
												<ProfilePicture
													user={currUser.user}
													picClass="halfProfilePicture"
													fakeClass="halfFakeProfilePicture"
												/>
												{#if currUser.user.id === hostId}
													<div class="name">
														{hostName}
													</div>
												{:else}
													<div class="name">
														{processName(currUser.user)}
													</div>
												{/if}
											</div>
											<div
												class="userScore"
												style="background : {currUser.score === scoreLeaders[0].score
													? '#219653'
													: scoreLeaders.length !== usersArray.length &&
													currUser.score === usersArray[usersArray.length - 1].score
													? '#EB5757'
													: '#F2994A'}"
											>
												{scoreOfUser[currUser.user.id] !== undefined
													? scoreOfUser[currUser.user.id]
													: 0}/{currentQuestionNumber + 1}
											</div>
										</div>
									{/if}
									<div
										class="user you"
										class:hid={isFloating}
										bind:this={userScoreCoord}
										class:winner={currUser.score === scoreLeaders[0].score}
									>
										<div class="userDetails">
											<ProfilePicture
												user={currUser.user}
												picClass="halfProfilePicture"
												fakeClass="halfFakeProfilePicture"
											/>
											{#if currUser.user.id === hostId}
												<div class="name">
													{hostName}
												</div>
											{:else}
												<div class="name">
													{processName(currUser.user)}
												</div>
											{/if}
										</div>
										<div
											class="userScore"
											style="background : {currUser.score === scoreLeaders[0].score
												? '#219653'
												: scoreLeaders.length !== usersArray.length &&
												currUser.score === usersArray[usersArray.length - 1].score
												? '#EB5757'
												: '#F2994A'}"
										>
											{scoreOfUser[currUser.user.id] !== undefined
												? scoreOfUser[currUser.user.id]
												: 0}/{currentQuestionNumber + 1}
										</div>
									</div>
								{:else} 
									<div
										class="user"
										class:winner={currUser.score === scoreLeaders[0].score}
									>
										<div class="userDetails">
											<ProfilePicture
												user={currUser.user}
												picClass="halfProfilePicture"
												fakeClass="halfFakeProfilePicture"
											/>
											{#if currUser.user.id === hostId}
												<div class="name">
													{hostName}
												</div>
											{:else}
												<div class="name">
													{processName(currUser.user)}
												</div>
											{/if}
										</div>
										<div
											class="userScore"
											style="background : {currUser.score === scoreLeaders[0].score
												? '#219653'
												: scoreLeaders.length !== usersArray.length &&
												currUser.score === usersArray[usersArray.length - 1].score
												? '#EB5757'
												: '#F2994A'}"
										>
											{scoreOfUser[currUser.user.id] !== undefined
												? scoreOfUser[currUser.user.id]
												: 0}/{currentQuestionNumber + 1}
										</div>
									</div>
								{/if}
							{/each}
						</div>
					</div>
				</div>
			</div>
		</div>
		{#if isHost && currentQuestionNumber === 9}
			<CustomButton btnText={"Start New Game"} disableBtn={disableContinueBtn} on:click={handleContinueButton} />
		{/if}
	</div>
{:else}
	<div bind:this={svgId} />
	<YouAreOffline />
{/if}

<style>
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-track {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: #ababab;
		border: 2px solid none;
		border-radius: 5px;
	}

	@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

	.svgContainer {
		position: absolute;
		width: calc(100% - 2rem);
		height: 100%;
		border-radius: 1rem;
		overflow: hidden;
	}

	.svg > * {
		stroke-width: 2rem;
		stroke: #ccc;
	}
	.contentHeaderContainer {
		position: absolute;
		display: flex;
		width: calc(100% - 2rem);
		justify-content: center;
		align-items: center;
		height: 1.5rem;
		top: -0.5rem;
	}
	.contentHeader {
		padding: 0.3rem 1.6rem;
		font-family: "Manrope";
		font-size: 0.9rem;
		font-weight: 700;
		color: #fff;
		border-radius: 0.25rem;
		z-index: 100;
	}

	.parentContainer {
		display: flex;
		width: 100%;
		margin-top: 15vh;
		margin-bottom: 3vh;
		justify-content: center;
	}

	.answerScreen {
		display: flex;
		flex-direction: column;
		padding: 0.8rem;
		overflow-y: auto;
		overflow-x: visible;
		gap: 1.2rem;
		background: #fff;
		border-radius: 1rem;
		width: 100%;
	}

	.resultScreenContainer {
		opacity:0;  
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:1s;
		padding: 0rem 1rem;
		position: relative;
		width: 500px;
	}

	.resultScreen {
		position: relative;
		padding: 0.2rem;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: visible;
		width: 100%;
		box-shadow: 0px 12px 0px #00000024;
	}

	.content {
		font-family: "Manrope";
		font-size: 1rem;
		font-weight: 800;
		max-width: 80%;
		margin: 0rem auto;
		text-align: center;
		line-height: 1.25rem;
		color: #333;
		margin-top: 2%;
	}

	.leaderboard {
		background: #f7f7f7;
		border-radius: 6px;
		min-height: 26vh;
		max-height: 26vh;
		overflow-y: auto;
		margin: 1%;
		padding: 1%;
		margin-top: -2%;
	}

	.halfTime {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
		padding-top: 2%;
	}

	.user {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 5px;
		margin: 1%;
	}
	.you {
		background: #6c44a8;
		color: #fff;
		border-radius: 0.5rem;
	}
	.you .name {
		color: #fff;
	}
	.winner {
		background: #ffb64a;
		color: #333333;
		border-radius: 0.5rem;
	}
	.winner .name {
		color: #333333;
	}
	.userDetails {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.name {
		font-family: "Manrope";
		font-weight: 500;
		font-size: 0.8rem;
		color: #0c0030;
		white-space: nowrap;
	}
	.userScore {
		font-family: "Manrope";
		font-weight: 700;
		font-size: 0.75rem;
		color: #fff;
		background: #333;
		padding: 0.6rem 0.4rem;
		border-radius: 0.6rem;
	}

	.floating {
		position: absolute;
		width: 85%;
		bottom: 2%;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}

	.floatingTop {
		bottom: 25vh;
	}

	.hid {
		visibility: hidden;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {
		.parentContainer {
			margin-top: 10vh;
			margin-bottom: 10vh;
		}

		.resultScreenContainer {
			width: 450px;
		}

		.content {
			font-size: 0.9rem;
			margin-top: 0.5rem;
			max-width: 90%;
		}

		.leaderboard {
			min-height: 35vh;
			max-height: 35vh;
		}

		.floatingTop {
			bottom: 34vh;
		}

		.user {
			padding: 0.4rem 0.6rem;
			font-size: 0.6rem;
		}

		.answerScreen {
			gap: 0.6rem;
		}

		.halfTime {
			overflow-y: auto;
		}
	}

	@media screen and (max-height: 650px) and (orientation: landscape) {
		.parentContainer {
			margin-top: 10vh;
			margin-bottom: 10vh;
		}

		.resultScreenContainer {
			width: 400px;
		}

		.content {
			font-size: 0.9rem;
			margin-top: 0.5rem;
			max-width: 90%;
		}

		.leaderboard {
			min-height: 35vh;
			max-height: 35vh;
		}

		.floatingTop {
			bottom: 34vh;
		}

		.user {
			padding: 0.4rem 0.6rem;
			font-size: 0.6rem;
		}

		.answerScreen {
			gap: 0.5rem;
		}

		.halfTime {
			overflow-y: auto;
		}
	}

	@media screen and (max-width: 600px) and (orientation: portrait) {
		.parentContainer {
			margin-top: 20vh;
			margin-bottom: 3vh;
		}

		.resultScreenContainer {
			width: 450px;
		}

		.content {
			font-size: 1rem;
			margin-top: 1rem;
			max-width: 90%;
		}

		.user {
			padding: 0.7rem 0.5rem;
			font-size: 0.6rem;
		}

		.answerScreen {
			gap: 1.5rem;
		}
	}

	@media screen and (max-width: 500px) and (orientation: portrait) {
		.resultScreenContainer {
			width: 90vw;
		}
	}
</style>
