<script>
	import {
		dbCurrentQuestionNumber,
		listenFirebaseKey,
		dbAllQuestion,
		dbQuestionTimer,
		dbUsers,
		dbGameSessionRoundValue,
		dbCategoryName,
		dbNextQuestionWaitingTimer,
		dbHost,
		dbDisableTimer,
		dbBgColor,
	} from "../utilities/database";
	import { playSound } from "../utilities/audio";
	import { getParams } from "../utilities/utils";
	import { fly } from "svelte/transition";
	import { onDestroy, onMount } from "svelte";
	import ProfilePicture from "../components/ProfilePicture.svelte";
	import CorrectAnswer from "../icons/CorrectAnswer.svelte";
	import WrongAnswer from "../icons/WrongAnswer.svelte";
	import NoAnswer from "../icons/NoAnswer.svelte";
	import { defaultColor } from "../utilities/utils";
	import { gameStart } from "../utilities/store";
	import TriviaIconNew from "../icons/TriviaIconNew.svelte";
	import LockedOption from "../icons/LockedOption.svelte";
	import UnLockedOption from "../icons/UnLockedOption.svelte";

	let currentQuestionNumber;
	let allQuestions;
	let currentQuestionText;
	let options = [];
	let borderColor = "#14AC11";
	let borderColor2 = "#6c44a8";
	let questionTimer;
	let remTime;
	let users;
	let pageHasRefreshed = true;
	let requestAnimationFrameId, requestAnimationFrameId2;
	let nextQuestionWaitingTimer;
	let svgId, svgId2;
	let stroke, strokeM;
	let maxValueOfStroke, maxValueOfStroke2;
	let strokeDashOffset, strokeDashOffset2;
	let stroke1, strokeM1;
	let initialTime = 16;
	let timeVal, timeVal1, timeValM, timeValM1;
	let usersStatus = [];
	let flipped = true;
	let isCorrect = false;
	let userId = getParams("userId");
	let noOfOnlinePlayers = 0;
	let showStatus = true;
	let showFact = false;
	let factText = "";

	let isTooltipRead = localStorage.getItem("da-trivia-isTooltipRead") || true;

	onMount(() => {
		maxValueOfStroke = 2 * (svgId.offsetWidth + svgId.offsetHeight);
		strokeDashOffset = svgId.offsetWidth / 2;
		stroke1 = maxValueOfStroke;
		stroke = 0;

		maxValueOfStroke2 = 2 * (svgId2.offsetWidth + svgId2.offsetHeight);
		strokeDashOffset2 = svgId2.offsetWidth / 2;
		strokeM1 = maxValueOfStroke2;
		strokeM = 0;
	});

	onDestroy(() => {
		cancelAnimationFrame(requestAnimationFrameId);
		cancelAnimationFrame(requestAnimationFrameId2);
	});

	listenFirebaseKey(dbNextQuestionWaitingTimer, dbNextQuestionWaitingTimerRef => {
		dbNextQuestionWaitingTimerRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			nextQuestionWaitingTimer = snap.val();

			if (nextQuestionWaitingTimer > 5) {
				showStatus = true;
				flipped = true;
				showFact = false;
				factText = "";
				pageHasRefreshed = true;
			} else {
				flipped = false;
				timeValM1 = nextQuestionWaitingTimer;
				if (nextQuestionWaitingTimer <= 0) {
					showStatus = false;
					cancelAnimationFrame(requestAnimationFrameId2);
					strokeM = 0;
					strokeM1 = maxValueOfStroke2 - strokeM;

					if (currentQuestionNumber === 4 || currentQuestionNumber === 9) {
						playSound("LEADERBOARDTRANSITION");
					}
				} else if (pageHasRefreshed) {
					pageHasRefreshed = false;
					prev2 = null;
					timeValM = nextQuestionWaitingTimer;
					cancelAnimationFrame(requestAnimationFrameId2);
					requestAnimationFrameId2 = requestAnimationFrame(setStrokeToMomentOfTruth);
				}
			}
		});
	});

	listenFirebaseKey(dbCurrentQuestionNumber, dbCurrentQuestionNumberRef => {
		dbCurrentQuestionNumberRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			currentQuestionNumber = snap.val();
			selectedOptionId = undefined;
			lockedOptionId = undefined;
			questionTimer = 15;
			usersStatus = [];
			borderColor = "#14AC11";
			pageHasRefreshed = true;
		});
	});

	dbUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		users = snap.val();
	});

	$: {
		if (users && allQuestions && !(currentQuestionNumber == null)) {
			noOfOnlinePlayers = 0;
			usersStatus = [];
			let currentQuestionUsersAnswers = allQuestions[currentQuestionNumber]["usersAnswers"];
			let currentQuestionCorrectOption = allQuestions[currentQuestionNumber]["correctOption"];
			for (const id in users) {
				if (users[id].isOnline === true) {
					noOfOnlinePlayers += 1;
					let obj = {
						userName: users[id]["userName"],
						profilePicture: users[id]["profilePicture"],
						id,
					};
					if (currentQuestionUsersAnswers && !(currentQuestionUsersAnswers[id] == null)) {
						obj["locked"] = true;
						if (currentQuestionUsersAnswers[id] === currentQuestionCorrectOption) {
							obj["answerStatus"] = "correct";
						} else {
							obj["answerStatus"] = "wrong";
						}
					} else {
						obj["locked"] = false;
					}
					usersStatus.push(obj);
				}
			}
			usersStatus = usersStatus;
		}
	}

	listenFirebaseKey(dbQuestionTimer, dbQuestionTimerRef => {
		dbQuestionTimerRef.on("value", snap => {
			if (!snap.exists()) {
				questionTimer = 15;
				return;
			} else {
				initialTime = snap.val();
				if (snap.val() >= 16) {
					isCorrect = false;
					flipped = true;
					remTime = 15;
				} else {
					remTime = snap.val();
				}
			}

			if (initialTime === 3 && lockedOptionId === undefined) {
				playSound("THREECOUNTDOWN");
			}

			// questionTimer = remTime/1000;
			questionTimer = remTime;
			if (questionTimer > 10) {
				borderColor = "#14AC11";
			} else if (questionTimer > 5) {
				borderColor = "#F2C94C";
			} else if (questionTimer > 0) {
				borderColor = "#C81919";
			}
			timeVal1 = initialTime;
			if (initialTime <= 0) {
				cancelAnimationFrame(requestAnimationFrameId);
				stroke = 0;
				
				if (selectedOptionId !== undefined && lockedOptionId === undefined) {
					listenFirebaseKey(dbAllQuestion, dbAllQuestionRef => {
						dbAllQuestionRef.child(currentQuestionNumber).child("usersAnswers").child(userId).set(selectedOptionId);
					});
				}

				stroke1 = maxValueOfStroke - stroke;
				playSound("THREECOUNTDOWNSTOP");
			} else if (initialTime < 16 && pageHasRefreshed) {
				pageHasRefreshed = false;
				prev = null;
				timeVal = initialTime;
				cancelAnimationFrame(requestAnimationFrameId);
				requestAnimationFrameId = requestAnimationFrame(setStroke);
			}
		});
	});

	let prev, prev2, curr, curr2, elapsed, elapsed2;
	function setStroke(timeStamp) {
		if (!svgId) {
			return;
		}
		if (!prev) {
			prev = timeStamp;
			timeVal = timeVal1;
		} else {
			curr = timeStamp;
			elapsed = curr - prev;
			timeVal = timeVal - elapsed / 1000;
			prev = curr;
		}
		maxValueOfStroke = 2 * (svgId.offsetWidth + svgId.offsetHeight);
		strokeDashOffset = 1 * (svgId.offsetWidth / 2);
		if (timeVal <= 0) {
			cancelAnimationFrame(requestAnimationFrameId);
			prev = null;
			stroke = 0;
			stroke1 = maxValueOfStroke - stroke;
		} else {
			stroke = ((15 - timeVal) * maxValueOfStroke) / 15;
			stroke1 = maxValueOfStroke - stroke;
			requestAnimationFrameId = requestAnimationFrame(setStroke);
		}
	}

	function setStrokeToMomentOfTruth(timeStamp) {
		if (!svgId2) {
			return;
		}
		if (!prev2) {
			prev2 = timeStamp;
			timeValM = timeValM1;
		} else {
			curr2 = timeStamp;
			elapsed2 = curr2 - prev2;
			timeValM = timeValM - elapsed2 / 1000;
			prev2 = curr2;
		}

		maxValueOfStroke2 = 2 * (svgId2.offsetWidth + svgId2.offsetHeight);
		strokeDashOffset2 = 1 * (svgId2.offsetWidth / 2);

		if (timeValM <= 0) {
			cancelAnimationFrame(requestAnimationFrameId2);
			prev2 = null;
			strokeM = 0;
			strokeM1 = maxValueOfStroke2 - strokeM;
		} else {
			strokeM = ((5 - timeValM) * maxValueOfStroke2) / 5;
			strokeM1 = maxValueOfStroke2 - strokeM;
			requestAnimationFrameId2 = requestAnimationFrame(setStrokeToMomentOfTruth);
		}
	}

	listenFirebaseKey(dbAllQuestion, dbAllQuestionsRef => {
		dbAllQuestionsRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			allQuestions = snap.val();
		});
	});

	let selectedOptionId;
	let lockedOptionId;
	let answerStatus = [];
	let usersAnswers;
	let answerOptionId;

	$: {
		if (allQuestions != undefined && currentQuestionNumber != undefined) {
			answerStatus = [];
			currentQuestionText = allQuestions[currentQuestionNumber].question;
			usersAnswers = allQuestions[currentQuestionNumber]["usersAnswers"];
			answerOptionId = allQuestions[currentQuestionNumber]["correctOption"];
			if (usersAnswers) {
				lockedOptionId = usersAnswers[userId];
			}

			options = [];
			for (let i = 0; i <= 1; i++) {
				options.push({ optionText: allQuestions[currentQuestionNumber][i], optionId: i });
			}
			for (let i = 0; i < currentQuestionNumber; i++) {
				let currentQuestionUsersAnswers = allQuestions[i]["usersAnswers"];
				if (!currentQuestionUsersAnswers || currentQuestionUsersAnswers[userId] === undefined) {
					answerStatus.push(2);
				} else if (allQuestions[i]["usersAnswers"][userId] === allQuestions[i]["correctOption"]) {
					answerStatus.push(1);
				} else {
					answerStatus.push(0);
				}
			}
			for (let i = currentQuestionNumber; i < 10; i++) {
				if (i === currentQuestionNumber) {
					answerStatus.push(3);
				} else {
					answerStatus.push(2);
				}
			}
		}
	}

	// handle fack check appearance
	$: {
		if (
			questionTimer === 0 &&
			allQuestions &&
			currentQuestionNumber != undefined &&
			currentQuestionNumber !== null
		) {
			if (allQuestions[currentQuestionNumber]["fact-text"] !== "" 
			&& allQuestions[currentQuestionNumber]["correctOption"] === 1) {
				showFact = true;
				factText = allQuestions[currentQuestionNumber]["fact-text"];
			}

			showFact = showFact;
		}
	}

	$: {
		if (
			questionTimer === 0 &&
			allQuestions &&
			currentQuestionNumber != undefined &&
			currentQuestionNumber !== null
		) {
			if (answerOptionId === lockedOptionId) {
				borderColor = "#14AC11";
			} else if (lockedOptionId != undefined) {
				borderColor = "#EB5757";
			} else {
				borderColor = "#4F4F4F";
			}
		}
	}

	$: {
		if (questionTimer === 0 && answerOptionId === lockedOptionId) {
			playSound("CORRECT");
			isCorrect = true;
		} else if (questionTimer === 0 && answerOptionId !== lockedOptionId && lockedOptionId !== undefined) {
			playSound("WRONG");
		}
	}

	function handleLockInBtn() {
		playSound("CLICK");
		playSound("THREECOUNTDOWNSTOP");

		isTooltipRead = false;
		localStorage.setItem("da-trivia-isTooltipRead", false);
		
		// selectedOptionId = optionId;
		listenFirebaseKey(dbAllQuestion, dbAllQuestionRef => {
			dbAllQuestionRef.child(currentQuestionNumber).child("usersAnswers").child(userId).set(selectedOptionId);
		});
	}

	function handleOptionClick(option) {
		
		if (lockedOptionId === undefined) {
			playSound("CLICK");

			selectedOptionId = option.optionId;
			return;
		}
	}

	let colorMap = {
		0: "#EB5757",
		1: "#14AC11",
		2: "#C4C4C4",
		3: "#6C44A8",
	};

	let titleMap = {
		0: "Wrong Answer given",
		1: "Correct Answer given",
		2: "No answer given",
		3: "Current Question",
	};

	function processName(user) {
		let name = user.userName;
		let fname = name?.split(" ")[0];
		let lname = name?.split(" ")[1];
		if (fname?.length > 7) {
			fname = fname[0].toUpperCase();
			if (lname) {
				fname += lname[0].toUpperCase();
			}
		}
		if (user.id === userId) {
			fname = fname + "(You)";
		}
		return fname;
	}

	$: {
		if (flipped || !flipped) {
			playSound("FLIP");
		}
	}
</script>

<svelte:window />
<div class="gameContainer" onmousedown="return false" onselectstart="return false">
	{#if isCorrect}
		<div class="bubble-wrap">
			{#each [...Array(30)] as elem}
				<div class="bubble" />
			{/each}
		</div>
	{/if}

	<TriviaIconNew />
	{#key currentQuestionNumber}
		<div class="parentContainer" class:flipper={!flipped}>
			<div class="answerScreenContainer back">
				<div class="questionTimerContainer">
					<div class="questionTimer" style="background : #6c44a8">Moment of Truth</div>
				</div>
				<div class="svgContainer" bind:this={svgId2}>
					<svg class="svg" width="100%" height="100%">
						<rect
							x="0"
							y="0"
							width="100%"
							height="100%"
							fill={borderColor2}
							stroke-dashoffset={-1 * strokeDashOffset2}
							stroke-dasharray="{strokeM} , {strokeM1}"
						/>
					</svg>
				</div>
				<div class="answerScreenParent">
					<div class="answerScreen" class:answerScreenLessGap={showFact}>
						<div class="question">Woah! Let's Keep it Going!</div>
						<div>
							{#if showFact}
								<div class="factStatement">
									{factText}
								</div>
							{/if}
							<div class="roomAnswers" class:roomAnswersFact={showFact}>
								{#each usersStatus as player}
									<div class="player">
										<div class="playerDetails">
											<ProfilePicture
												user={player}
												picClass="gameProfilePicture"
												fakeClass="gameFakeProfilePicture"
											/>
											<div class="playerName">
												{processName(player)}
											</div>
										</div>
										<div class="answerStatus">
											{#if showStatus === true}
												{#if player["answerStatus"] === "correct"}
													<CorrectAnswer />
												{:else if player["answerStatus"] === "wrong"}
													<WrongAnswer />
												{:else}
													<NoAnswer />
												{/if}
											{/if}
										</div>
									</div>
								{/each}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="answerScreenContainer front"
				class:scaleAnimation={isCorrect}
				in:fly={{ y: -20, duration: 200, delay: 200 }}
			>
				<div class="questionTimerContainer">
					{#if questionTimer != undefined}
						<div
							class="questionTimer"
							style="background : {borderColor}"
							class:timesUp={questionTimer === 0 && lockedOptionId == null}
						>
							{#if questionTimer > 9}
								0:{questionTimer}
							{:else if questionTimer > 0}
								0:0{questionTimer}
							{:else if questionTimer === 0}
								{#if answerOptionId === lockedOptionId}
									Correct
								{:else if lockedOptionId != undefined}
									Incorrect
								{:else}
									Times Up!
								{/if}
							{/if}
						</div>
					{:else}
						<div class="questionTimer" style="background : {borderColor}">0:15</div>
					{/if}
				</div>
				<div class="svgContainer" bind:this={svgId}>
					<svg class="svg" width="100%" height="100%">
						<rect
							x="0"
							y="0"
							width="100%"
							height="100%"
							fill={borderColor}
							stroke-dashoffset={-1 * strokeDashOffset}
							stroke-dasharray="{stroke} , {stroke1}"
						/>
					</svg>
				</div>
				<div class="answerScreenParent" onmousedown="return false" onselectstart="return false">
					<div class="answerScreen">
						<div class="question" onmousedown="return false" onselectstart="return false">
							{#if currentQuestionText}
								{currentQuestionText}
							{/if}
						</div>
						<div class="allOptions">
							{#each options as option}
								{#if questionTimer === 0}
									{#if option.optionId === answerOptionId}
										<div class="correctOption">
											{option.optionText}
										</div>
									{:else if option.optionId === lockedOptionId}
										<div class="wrongOption">
											{option.optionText}
										</div>
									{:else}
										<div class="simpleOption">
											{option.optionText}
										</div>
									{/if}
								{:else}
									<div
										class="option"
										class:hoverOption={option.optionId !== selectedOptionId}
										class:selectedOption={option.optionId == selectedOptionId}
										style="cursor:pointer"
										on:click={() => handleOptionClick(option)}
									>
										{option.optionText}
										{#if option.optionId === selectedOptionId}
											{#if lockedOptionId !== undefined}
												<div class="lockedOption">
													<LockedOption />
												</div>
											{:else} 
												<div class="unlockedOption" on:click={handleLockInBtn}>

												{#if isTooltipRead === true}
													<div class="tooltip">
														Lock your answer to confirm. Selected answers get locked automatically on time-up.
													</div>
												{/if}
													<UnLockedOption />
												</div>
											{/if}
										{/if}
										
									</div>
								{/if}
							{/each}
						</div>
						<div>
							<div class="allAnswers">
								{#each answerStatus as status}
									{#if status !== 3}
										<div title={titleMap[status]}>
											<svg height="1rem" width="1rem">
												<circle cx="0.5rem" cy="0.5rem" r="0.5rem" fill={colorMap[status]} />
											</svg>
										</div>
									{:else}
										<div class="currentQuestionContainer" title={titleMap[status]}>
											<svg height="2rem" width="2rem">
												<circle cx="1rem" cy="1rem" r="1rem" fill={"#6C44A8"} />
											</svg>
											<div class="currentQuestion">
												{currentQuestionNumber + 1}
											</div>
										</div>
									{/if}
								{/each}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	{/key}
</div>

<style lang="scss">
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: initial;
		border-radius: 4px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #172072;
		border-radius: 4px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #0e1346;
	}

	.flipper {
		transform: rotateY(180deg);
	}

	@keyframes move {
		100% {
			transform: translate3d(0, 0, 0);
		}
	}

	@function randomNum($min, $max) {
		$rand: random();
		$randomNum: $min + floor($rand * (($max - $min) + 1));

		@return $randomNum;
	}
	.unlockedOption, 
	.lockedOption{
		background-color: #ECF041;
		border-radius: 50%;
		padding: 0.5rem 0.7rem;
		position: absolute;
		box-sizing: border-box;
		right: 8%;
		box-shadow: 0px 8px 16px rgba(48, 48, 48, 0.27);
	}

	.lockedOption {
		opacity: 0.5;
		background-color: #ECF041;
	}

	.bubble-wrap {
		margin: 0 auto;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 100%;
		transform-style: preserve-3d;
		transform-origin: center center;
		perspective: 300px;
		overflow: hidden;
	}
	.bubble {
		position: absolute;
		opacity: 0.7;
		box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
		border-radius: 50%;
		animation: move 5s ease-in-out reverse infinite;
	}
	@for $i from 1 through 30 {
		.bubble:nth-child(#{$i}) {
			$size: random(50) + px;
			height: $size;
			width: $size;
			animation-delay: -$i * 0.1s;
			transform: translate3d(#{randomNum(-1000, 1000)}px, #{randomNum(-1000, 1000)}px, 0);
			background: rgba(161, 161, 161, 0.575);
		}
	}

	.gameContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
		padding-top: 2%;
	}
	.parentContainer {
		display: flex;
		width: 100%;
		transition: 0.6s;
		transform-style: preserve-3d;
		justify-content: center;
		margin-top: 15vh;
	}

	.player {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		color: #333;
		padding: 0.5rem;
	}
	.playerDetails {
		display: flex;
		gap: 10px;
		max-width: 80%;
		justify-content: flex-start;
		align-items: center;
	}
	.playerName {
		font-family: "Manrope";
		font-weight: 700;
		font-size: 0.8rem;
		white-space: nowrap;
	}

	.answerScreenContainer {
		padding: 0rem 1rem;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		position: absolute;
		width: 500px;
	}

	.front {
		z-index: 2;
		transform: rotateY(0deg);
	}

	/* back, initially hidden pane */
	.back {
		transform: rotateY(180deg);
	}

	.scaleAnimation {
		animation-duration: 2s;
		animation-name: scaleInOut;
	}

	.svgContainer {
		position: absolute;
		width: calc(100% - 2rem);
		height: 100%;
		border-radius: 1rem;
		overflow: hidden;
	}
	.svg > * {
		stroke-width: 2rem;
		stroke: #ccc;
	}
	.answerScreenParent {
		position: relative;
		padding: 0.2rem;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: visible;
		width: 100%;
		box-shadow: 0px 12px 0px #00000024;
	}
	.answerScreen {
		display: flex;
		flex-direction: column;
		padding: 0.8rem;
		overflow-y: auto;
		overflow-x: visible;
		gap: 2rem;
		background: #fff;
		border-radius: 1rem;
		width: 100%;
	}
	.answerScreenLessGap {
		gap: 0.5rem;
	}

	.question {
		font-family: "Manrope";
		font-size: 1.1rem;
		font-weight: 800;
		max-width: 100%;
		margin: 0rem auto;
		text-align: center;
		line-height: 1.25rem;
		color: #333;
		margin-top: 1rem;
	}
	.allOptions {
		display: grid;
		gap: 1.2rem;
		width: 95%;
		margin: 0rem auto;
	}

	.option,
	.selectedOption,
	.correctOption,
	.wrongOption,
	.simpleOption {
		border: 2px solid #d9d9d9;
		border-radius: 60px;
		color: #414141;
		padding: 1.1rem 1rem;
		text-align: center;
		font-family: "Manrope";
		font-size: 0.75rem;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.selectedOption {
		color: #fff;
		background: #6c44a8;
		border: 0px solid #fff;
	}
	.correctOption {
		background: #14ac11;
		color: #fff;
		animation: animateOption 2s 1;
		border: 0px solid #fff;
	}
	.wrongOption {
		background: #eb5757;
		color: #fff;
		border: 0px solid #fff;
	}
	.hoverOption:hover {
		transform: scale(1.02);
	}

	.unlockedOption:hover .tooltip {
		opacity: 1;
		visibility: visible;
	}

	.tooltip {
		position: absolute;
		bottom: 130%;
		background: #fff;
		color: #333;
		font-family: "Manrope";
		font-size: 0.7rem;
		padding: 0.4rem 0.5rem;
		border-radius: 10px;
		opacity: 0;
		box-shadow: 0px 8px 16px #0000001f;
		transition: opacity 0.3s;
		visibility: hidden;
		width: 20vw;
		font-weight: 500;
		right: 0vw;
	}
	.tooltip::after {
		content: "";
		position: absolute;
		top: 100%;
		border-width: 10.5px;
		border-style: solid;
		border-color: #fff transparent transparent transparent;
		right: 1vw;
	}

	@keyframes scaleInOut {
		from {
			transform: scale(1, 1);
		}

		20% {
			transform: scale(1.1, 1.1);
		}

		40% {
			transform: scale(1, 1);
		}

		60% {
			transform: scale(1.05, 1.05);
		}
		to {
			transform: scale(1, 1);
		}
	}

	@keyframes animateOption {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.025);
		}
		100% {
			transform: scale(1);
		}
	}
	.questionTimerContainer {
		position: absolute;
		display: flex;
		width: calc(100% - 2rem);
		justify-content: center;
		align-items: center;
		height: 1.5rem;
		top: -0.5rem;
	}
	.questionTimer {
		padding: 0.3rem 1.6rem;
		font-family: "Manrope";
		font-size: 0.9rem;
		font-weight: 700;
		color: #fff;
		border-radius: 0.25rem;
		z-index: 100;
	}
	.timesUp {
		animation: animateTimesUp 2s 1;
		background: #4f4f4f;
		padding: 0.25rem 0.5rem;
	}
	@keyframes animateTimesUp {
		33% {
			transform: rotate(10deg);
		}
		66% {
			transform: rotate(-10deg);
		}
		99% {
			transform: rotate(0deg);
		}
	}
	.allAnswers {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0.5rem;
		align-items: center;
	}

	.factStatement {
		background: #f7f7f7;
		border-radius: 6px;
		overflow-y: hidden;
		font-family: Manrope;
		font-weight: 500;
		font-size: 0.8rem;
		padding: 2%;
		margin-bottom: 0.5rem;
		text-align: center;
		min-height: 3vh;
		max-height: 7.7vh;
	}
	.roomAnswers, .roomAnswersFact {
		background: #f7f7f7;
		border-radius: 6px;
		min-height: 25vh;
		max-height: 25vh;
		overflow-y: auto;
	}

	.roomAnswersFact {
		min-height: 22vh;
		max-height: 22vh;
	}

	.currentQuestionContainer {
		position: relative;
		width: 2rem;
		height: 2rem;
	}
	.currentQuestion {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-family: "Manrope";
		font-size: 1rem;
		font-weight: 700;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {
		.parentContainer {
			margin-top: 14vh;
		}

		.answerScreenContainer {
			width: 450px;
		}

		.answerScreen { 
			gap: 1rem;
		}

		.question {
			font-size: 1rem;
			margin-top: 0.7rem;
			max-width: 93%;
		}

		.allOptions {
			gap: 1rem;
		}

		.option,
		.selectedOption,
		.correctOption,
		.wrongOption,
		.simpleOption {
			padding: 1.2rem 1rem;
			font-size: 0.8rem;
		}

		.unlockedOption, 
		.lockedOption{
			padding: 0.5rem 0.7rem;
			right: 9%;
		}

		.roomAnswers {
			min-height: 35vh;
			max-height: 35vh;
		}

		.answerScreen {
			gap: 0.6rem;
		}

		.currentQuestion {
			font-size: 0.7rem;
		}
		.gameContainer {
			overflow-y: auto;
		}

		.roomAnswersFact {
			min-height: 180px;
			max-height: 180px;
		}

		.factStatement {
			font-size: 0.7rem;
			max-height: 9vh;
		}
	}

	@media screen and (max-height: 650px) and (orientation: landscape) {
		.parentContainer {
			margin-top: 15vh;
		}

		.answerScreenContainer {
			width: 400px;
		}

		.allOptions {
			gap: 0.8rem;
		}

		.question {
			font-size: 0.9rem;
			max-width: 90%;
		}

		.unlockedOption, 
		.lockedOption{
			padding: 0.5rem 0.7rem;
			right: 10%;
		}

		.option,
		.selectedOption,
		.correctOption,
		.wrongOption,
		.simpleOption {
			padding: 0.9rem 1rem;
			font-size: 0.8rem;
		}

		.answerScreen {
			gap: 1rem;
		}

		.currentQuestion {
			font-size: 0.7rem;
		}

		.gameContainer {
			overflow-y: auto;
		}

		.roomAnswersFact {
			min-height: 190px;
			max-height: 190px;
		}

		.factStatement {
			font-size: 0.7rem;
			max-height: 9vh;
		}
	}

	@media screen and (max-width: 600px) and (orientation: portrait) {
		.parentContainer {
			margin-top: 20vh;
		}

		.answerScreenContainer {
			width: 450px;
		}

		.question {
			font-size: 1rem;
			margin-top: 1rem;
			max-width: 90%;
		}

		.questionTimer {
			padding: 0.3rem 2.6rem;
			font-size: 1rem;
		}

		.option,
		.selectedOption,
		.correctOption,
		.wrongOption,
		.simpleOption {
			padding: 1rem 1rem;
			font-size: 0.8rem;
		}

		.unlockedOption, 
		.lockedOption{
			padding: 0.5rem 0.7rem;
			right: 8%;
		}

		.answerScreen {
			gap: 1.5rem;
		}

		.currentQuestion {
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 500px) and (orientation: portrait) {
		.answerScreenContainer {
			width: 90vw;
		}

		.tooltip {
			width: 50vw;
		}

		.unlockedOption, 
		.lockedOption{
			padding: 0.5rem 0.7rem;
			right: 9%;
		}
	}

	@media screen and (min-width: 500px) and (max-width: 850px) and (orientation: portrait) {
		.answerScreenContainer {
			width: 70vw;
		}

		.tooltip {
			width: 30vw;
		}

		.unlockedOption, 
		.lockedOption{
			padding: 0.5rem 0.7rem;
			right: 9%;
		}
	}
</style>
