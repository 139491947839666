export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};

export const defaultColor = "radial-gradient(50% 50% at 50% 50%, #2D5985 0%, #16265F 100%)";

export function changeToThemePage() {
	window?.DIVE_APP?.changeToAppThemePage?.();
}
