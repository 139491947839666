<script>
	import {
		dbUsers,
		dbHost,
		listenFirebaseKey,
		dbPage,
		dbCategoryName,
		dbHostAction,
		dbGameSessionRound,
		dbTheme,
	} from "../utilities/database";
	import { changeToThemePage, getParams } from "../utilities/utils";
	import Tick from "../components/OnlineTick.svelte";
	import DisconnectedSvg from "../components/DisconnectedSvg.svelte";
	import CustomButton from "../components/CustomButton.svelte";
	import IconLarge from "../icons/IconLarge.svelte";
	import TriviaIconNew from "../icons/TriviaIconNew.svelte";
	import ProfilePicture from "../components/ProfilePicture.svelte";
	import { playSound } from "../utilities/audio";
	import { onMount } from "svelte";

	let hostId;
	let isHost;
	let users;
	let usersArray = [];
	let userId = getParams("userId");
	let categoryName;
	let noOfOnlinePlayers = 0;
	let disableStartGameBtn = false;
	let page;
	let roundValue;
	const defaultTheme = {
		icon: "🎁",
		name: "Default theme",
		description: "Let us pick a theme for you!",
		th_id: "default",
	};
	onMount(async () => {
		await dbTheme().once("value", async snap => {
			if (!snap.exists()) {
				console.log("no theme");
				await dbTheme().set(defaultTheme);
			}
		});
	});
	listenFirebaseKey(dbCategoryName, dbCategoryNameRef => {
		dbCategoryNameRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			categoryName = snap.val();
		});
	});

	dbUsers.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		users = snap.val();
	});

	$: {
		if (users) {
			usersArray = [];
			noOfOnlinePlayers = 0;
			for (const id in users) {
				usersArray.push(users[id]);
				if (users[id].isOnline) {
					noOfOnlinePlayers += 1;
				}
			}
		}
	}

	let hostName;
	dbHost.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		hostId = snap.val();
		if (hostId === userId) {
			isHost = true;
		} else {
			isHost = false;
		}
	});

	$: {
		if (hostId && users) {
			hostName = users[hostId]["userName"].split(" ")[0];
		}
	}
	function processName(currUser) {
		let name = currUser.userName;
		let fname = name?.split(" ")[0];
		if (fname?.length > 7) {
			fname = fname[0].toUpperCase();
			if (name?.split(" ")[1]) {
				fname += name?.split(" ")[1][0].toUpperCase();
			}
		}
		if (currUser.id === hostId) {
			if (currUser.id === userId) {
				fname = "You";
			}
			fname = fname + " (Host)";
		} else if (currUser.id === userId) {
			fname += " (You)";
		}
		return fname;
	}

	listenFirebaseKey(dbPage, dbPageRef => {
		dbPageRef.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			page = snap.val();
		});
	});
	let disbleChooseCategory = false;
	function handleChooseCategory() {
		playSound("CLICK");
		if (disbleChooseCategory) {
			return;
		}
		disbleChooseCategory = true;
		listenFirebaseKey(dbGameSessionRound, async dbGameSessionRoundRef => {
			await dbGameSessionRoundRef.update({
				page: "Category",
			});
		});
		//changePageToChooseCategory.set(1);
	}
	function handleChangeCategory() {
		playSound("CLICK");
		listenFirebaseKey(dbGameSessionRound, async dbGameSessionRoundRef => {
			await dbGameSessionRoundRef.update({
				page: "Category",
			});
		});
	}

	function handleStartGame() {
		playSound("CLICK");
		if (disableStartGameBtn) {
			return;
		}
		disableStartGameBtn = true;
		listenFirebaseKey(dbGameSessionRound, async dbGameSessionRoundRef => {
			/**
			 * first update the page to Timer.
			 * then start the countDown timer.
			 */
			await dbGameSessionRoundRef.update({
				page: "Timer",
			});

			await dbGameSessionRoundRef.update({
				countDownTimer: 5,
			});

			listenFirebaseKey(dbHostAction, async dbHostActionRef => {
				await dbHostActionRef.set({
					action: "Start Timer",
					time: Date.now(),
				});
			});
		});
	}
</script>

<div class="welcomeContainer">
	<TriviaIconNew />
	<div class="waitingMsg">
		{#if isHost}
			{#if !categoryName}
				Select a category
			{:else}
				Start the game
			{/if}
		{:else if !categoryName}
			{#if hostName}
				Ask {hostName}(host) to select a category
			{:else}
				Ask host to select a category
			{/if}
		{:else}
			Waiting for {hostName}(Host) to start the game
		{/if}
	</div>
	<div class="playersContainer">
		{#if hostId && users}
			<div class="host">
				<div
					class="imageContainer"
					title={users[hostId].userName}
					style="width : 5rem; height : 5rem ;border : 0.25rem solid {users[hostId].isOnline
						? '#40BB45'
						: '#AC312F'}"
				>
					{#if users[hostId].isOnline}
						<Tick iconSize="1rem" />
					{:else}
						<DisconnectedSvg iconSize="1rem" />
					{/if}
					<ProfilePicture user={users[hostId]} picClass="profilePicture" fakeClass="fakeProfilePicture" />
				</div>

				<div class="name" style="font-size : 0.85rem">
					{processName(users[hostId])}
				</div>
			</div>
		{/if}
		<div class="normalPlayerContainer">
			{#each usersArray as currUser}
				{#if currUser.id !== hostId}
					<div class="normalPlayer">
						<div
							class="imageContainer"
							title={currUser.userName}
							style="border : 0.2rem solid {currUser.isOnline ? '#40BB45' : '#AC312F'}"
						>
							{#if currUser.isOnline}
								<Tick iconSize="0.7rem" />
							{:else}
								<DisconnectedSvg iconSize="0.75rem" />
							{/if}
							<ProfilePicture user={currUser} picClass="profilePicture" fakeClass="fakeProfilePicture" />
						</div>

						<div class="name">
							{processName(currUser)}
						</div>
					</div>
				{/if}
			{/each}
		</div>
	</div>
	{#if categoryName}
		<div class="chooseCategoryContainer">
			Choosen category - <span class="categoryName" title={isHost ? "" : "Host can change the category"}>
				{categoryName}
			</span>
			{#if isHost}
				<span class="changeCategory" on:click={changeToThemePage}> (change) </span>
			{/if}
		</div>
	{/if}
	{#if isHost}
		<!-- {#if !categoryName}
			<CustomButton
				btnText={"Choose Category"}
				on:click={changeToThemePage}
				disableBtn={disbleChooseCategory}
			/>
		{:else} -->
		{#if categoryName}
			<div class="buttonContainer">
				<!-- <CustomButton btnText = {"Change Category"} on:click = {handleChooseCategory} /> -->
				<CustomButton
					btnText={"Start Game"}
					on:click={handleStartGame}
					tooltipMsg={noOfOnlinePlayers < 2 ? "It is recommended to play with at least 2 users." : ""}
				/>
			</div>
		{/if}
	{/if}
</div>

<style>
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-track {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: #ababab;
		border: 2px solid none;
		border-radius: 5px;
	}
	.welcomeContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: 2%;
	}
	.playersContainer {
		margin: auto;
		max-height: 70vh;
		overflow-y: auto;
		z-index: 1;
	}
	.host,
	.normalPlayer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1rem;
	}
	.imageContainer {
		position: relative;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.25rem;
	}
	.name {
		font-family: "Manrope";
		font-size: 0.75rem;
		font-weight: 700;
		color: #fff;
		text-align: center;
	}
	.normalPlayerContainer {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-height: 200px;
		min-width: 50vw;
		max-width: 70vw;
		margin: 0 auto;
		overflow-y: auto;
		gap: 1rem;
	}
	.waitingMsg {
		font-family: "Manrope";
		font-size: 1rem;
		font-weight: 700;
		color: #fff;
		margin: 1rem 0rem;
		text-align: center;
		line-height: 1.5rem;
	}
	.buttonContainer {
		display: flex;
		gap: 1rem;
		justify-content: center;
		align-items: center;
	}
	.chooseCategoryContainer {
		font-family: "Manrope";
		font-size: 0.85rem;
		font-weight: 400;
		color: #fff;
		z-index: 1;
		margin: 1rem 0rem;
		letter-spacing: 0.025rem;
	}
	.categoryName {
		font-weight: 700;
	}
	.changeCategory {
		color: #ccc;
		cursor: pointer;
	}
</style>
